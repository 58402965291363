import React from "react";
import ReactDOM from "react-dom";

const App = () => {
  return (
    <div id="adambuildscolorado-app">
      <div className="img-container">
        <img
          src={require("./images/IrisStLogo-August2022-Black.png")}
          alt="Iris Street Renovation logo."
          width="512px"
          height="512px"
        />
      </div>
      <p>Copyright 2023</p>
    </div>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
